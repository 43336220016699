<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <v-row>
        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else> {{ stat.total }}</div>
              <v-spacer></v-spacer>
              <v-avatar color="blue" size="40">
                <v-icon dark>mdi-clipboard-list-outline</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              Total relevés
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else> {{ stat.today }}</div>
              <v-spacer></v-spacer>
              <v-avatar color="success" size="40">
                <v-icon dark>mdi-calendar-today-outline</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              Relevés d'aujourd'hui
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else> {{ stat.this_month }}</div>
              <v-spacer></v-spacer>
              <v-avatar color="indigo" size="40">
                <v-icon dark>mdi-calendar-multiselect</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              Relevés de ce mois
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else> {{ stat.this_year }}</div>
              <v-spacer></v-spacer>
              <v-avatar color="primary" size="40">
                <v-icon dark>mdi-calendar-month-outline</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              Relevés de cette année
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card :disabled="overlay" :loading="overlay" class="rounded-lg shadow">
            <v-card-title>

              <div>
                <v-text-field v-model="keyword" append-icon="mdi-magnify" class="w-300" clearable
                              dense
                              filled
                              hide-details
                              placeholder="Recherche..."
                              rounded single-line></v-text-field>
              </div>

              <v-spacer/>

              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-1" icon v-bind="attrs" @click="refresh" v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>

              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="blue"
                         dark
                         depressed
                         fab
                         small
                         v-bind="attrs"
                         @click="ExportExcel"
                         v-on="on">
                    <v-icon>mdi-download-outline</v-icon>
                  </v-btn>
                </template>
                <span>Expoter</span>
              </v-tooltip>

            </v-card-title>
            <v-skeleton-loader v-if="loading" type="table"/>
            <v-card-text v-if="!loading && statements.length === 0" class="text-center">
              <NoResult/>
            </v-card-text>
            <div v-if="!loading && statements.length > 0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Campagne</th>
                    <th>Superviseur</th>
                    <th>Nom du grossiste</th>
                    <th>Code du grossiste</th>
                    <th>Région du grossiste</th>
                    <th>Map</th>
                    <th>Image</th>
                    <th>Récompensé</th>
                    <th>Type de récom..</th>
                    <th>Distance(M)</th>
                    <th>QR codes gagnés</th>
                    <!--                    <th>KIP's gagnés</th>-->
                    <th>Total gagné</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in statements" :key="item.id">

                    <td class="font-weight-medium">
                      {{ item.statement_campaign ? item.statement_campaign.name : "-" }}
                    </td>

                    <td>
                      {{ item.supervisor ? item.supervisor.name : "-" }}
                    </td>

                    <td>
                      {{ item.wholesaler ? item.wholesaler.name : "-" }}
                    </td>

                    <td>
                      {{ item.wholesaler ? item.wholesaler.code : "-" }}
                    </td>

                    <td>
                      {{ item.wholesaler ? item.wholesaler.region : "-" }}
                    </td>

                    <td>
                      <div class="d-block text-no-wrap">
                        <a :href="'https://www.google.com/maps/search/?api=1&query=' + item.latitude + ',' + item.longitude"
                           class="blue--text text-decoration-none"
                           target="_blank">
                          <v-icon color="blue" dense>mdi-map-marker</v-icon>
                          {{ item.latitude }} - {{ item.longitude }}
                        </a>
                      </div>
                    </td>

                    <td>
                      <div class="pa-2">
                        <v-img v-if="item.image"
                               :src="FILE_URL + item.image"
                               class="rounded zoom-pointer"
                               height="45"
                               width="45" @click="[image = [{src: FILE_URL + item.image}],visible = true]"/>

                        <v-img v-else :src="require('@/assets/no-image.png')"
                               class="rounded img-border"
                               height="45"
                               width="45"/>
                      </div>
                    </td>

                    <td>
                      <v-icon v-if="item.is_rewarded" color="success">mdi-check-circle</v-icon>
                      <v-icon v-else color="red">mdi-close-circle</v-icon>
                    </td>


                    <td class="font-weight-medium blue--text">
                      <span v-if="item.rewarded_type !== 'none'">
                         {{ item.rewarded_type === "digital" ? "Digital" : "Physique" }}
                      </span>
                      <span v-else>-</span>
                    </td>


                    <td class="font-weight-medium">
                      {{ item.distance }}
                    </td>

                    <td>
                      <div v-if="item.qr_codes_sum_winning_points > 0">
                        <v-chip color="success" dark small>
                          <v-icon dark left small>mdi-check-circle</v-icon>
                          {{ item.qr_codes_sum_winning_points | toCurrency }}
                        </v-chip>
                      </div>

                      <div v-else>
                        <v-chip color="red" dark small>
                          <v-icon dark left small>mdi-close-circle</v-icon>
                          {{ 0 | toCurrency }}
                        </v-chip>
                      </div>
                    </td>

                    <!--                    <td>-->
                    <!--                      <div v-if="item.kpis_sum_winning_points > 0">-->
                    <!--                        <v-chip color="success" dark small>-->
                    <!--                          <v-icon dark left small>mdi-check-circle</v-icon>-->
                    <!--                          {{ item.kpis_sum_winning_points | toCurrency }}-->
                    <!--                        </v-chip>-->
                    <!--                      </div>-->

                    <!--                      <div v-else>-->
                    <!--                        <v-chip color="red" dark small>-->
                    <!--                          <v-icon dark left small>mdi-close-circle</v-icon>-->
                    <!--                          {{ 0 | toCurrency }}-->
                    <!--                        </v-chip>-->
                    <!--                      </div>-->
                    <!--                    </td>-->


                    <td>
                      <div v-if="(item.qr_codes_sum_winning_points + item.kpis_sum_winning_points) > 0">
                        <v-chip color="success" dark small>
                          <v-icon dark left small>mdi-check-circle</v-icon>
                          {{ (item.qr_codes_sum_winning_points + item.kpis_sum_winning_points) | toCurrency }}
                        </v-chip>
                      </div>

                      <div v-else>
                        <v-chip color="red" dark small>
                          <v-icon dark left small>mdi-close-circle</v-icon>
                          {{ 0 | toCurrency }}
                        </v-chip>
                      </div>
                    </td>


                    <td>
                      {{ item.created_at }}
                    </td>

                    <td>
                      <v-tooltip color="primary" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" icon v-bind="attrs" @click="deleteItem(item.id)" v-on="on">
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Supprimer</span>
                      </v-tooltip>
                    </td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-divider v-if="total > 10"/>

              <v-card-actions v-if="total > 10">

                <div>
                  <v-select v-model="per_page"
                            :items="[10,20,50,100,200,300,400,500]"
                            :style="{width: '120px'}"
                            dense
                            hide-details
                            label="Ligne par page"
                            outlined></v-select>
                </div>

                <v-spacer/>
                <v-pagination v-if="total>10"
                              v-model="pagination.current"
                              :length="pagination.total"
                              circle
                              total-visible="6"
                              @input="onPageChange"
                ></v-pagination>
              </v-card-actions>

            </div>
          </v-card>
        </v-col>
      </v-row>

      <vue-easy-lightbox :imgs="image"
                         :index="index"
                         :visible="visible"
                         escDisabled
                         moveDisabled
                         @hide="visible = false"></vue-easy-lightbox>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResult from "../../components/NoResult";

export default {
  components: {NoResult},
  data() {
    return {
      overlay: false,
      loading: false,

      id: '',
      statements: [],
      total: 0,
      per_page: 10,
      pagination: {
        current: 1,
        total: 0
      },
      keyword: '',

      stat: {},

      //Show image
      image: '',
      visible: false,
      index: 0,
      FILE_URL: process.env.VUE_APP_FILE_URL
    }
  },
  methods: {
    deleteItem(id) {
      this.id = id
      this.$confirm_dialog = true
    },
    getStatements() {
      this.loading = true
      this.drawer = false
      this.$Progress.start()

      HTTP.get('/statements?page=' + this.pagination.current, {
        params: {
          keyword: this.keyword,
          per_page: this.per_page,
        }
      }).then((res) => {
        this.loading = false
        this.$Progress.finish()
        this.statements = res.data.data.data
        this.stat = res.data.stat

        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },
    onPageChange() {
      this.getStatements();
    },
    ExportExcel() {
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.user.token
      let userId = this.$store.state.user.id
      let DownloadURL = baseUrl + '/export/excel/statements?token=' + token + '&id=' + userId;
      window.open(DownloadURL, '_blanc')
    },
    refresh() {
      this.getStatements()
    }
  },
  created() {
    this.getStatements()
  },
  watch: {
    keyword() {
      this.getStatements();
    },
    per_page() {
      this.pagination.current = 1
      this.getStatements();
    },
    '$is_confirm': function (el) {
      if (el) {
        this.$is_confirm = false
        this.overlay = true
        this.$Progress.start()
        HTTP.delete('/statements/delete/' + this.id).then(() => {
          let i = this.statements.findIndex(item => item.id === this.id)
          this.$delete(this.statements, i)
          this.$successMessage = 'Cet code qr a été supprimé avec succès'
          this.overlay = false
          this.$Progress.finish()
        }).catch(err => {
          this.overlay = false
          this.$Progress.fail()
          console.log(err)
        })
      }
    },
  },
}
</script>

<style scoped>

</style>